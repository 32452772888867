<template>
  <div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(submit)"
        class="is-label-14px-normal form-timeoff-type"
      >
        <div class="columns is-multiline">
          <div class="column is-12">
            <ValidationProvider
              name="Time Off Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                label="Name"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-input
                  v-model="data.name"
                  placeholder="Time Off Name"
                  class="custom-form-height"
                />
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-12">
            <ValidationProvider
              name="Date"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                label="Date"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
              >
                <b-datepicker
                  v-model="data.date"
                  placeholder="Date"
                  trap-focus
                  editable
                />
              </b-field>
            </ValidationProvider>
          </div>
          <div class="column is-3 has-text-weight-bold">Is Paid</div>
          <div class="column is-9">
            <b-switch v-model="data.isPaid" true-value="1" false-value="0">
              {{ data.isPaid == '1' ? 'Yes' : 'No' }}
            </b-switch>
          </div>
          <div class="column is-6" v-if="formMode === 'edit'">
            <b-button
              type="is-primary"
              outlined
              expanded
              @click="onDeleteClick"
              :loading="isLoadingDelete"
              :disabled="isLoading || isLoadingDelete"
            >
              {{ isLoading ? 'Deleting...' : 'Delete' }}
            </b-button>
          </div>
          <div class="column is-6" v-if="formMode === 'edit'">
            <b-button
              expanded
              native-type="submit"
              type="is-primary"
              :loading="isLoading"
              :disabled="isLoading || isLoadingDelete"
            >
              {{ isLoading ? 'Saving...' : 'Save' }}
            </b-button>
          </div>
          <div v-else class="column is-12">
            <b-button
              expanded
              native-type="submit"
              type="is-primary"
              :loading="isLoading"
              :disabled="isLoading || isLoadingDelete"
            >
              {{ isLoading ? 'Saving...' : 'Save' }}
            </b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
  props: {
    formMode: String,
    initialData: {
      type: Object,
      default: null,
    },
    leaveType: String,
  },
  data() {
    return {
      isLoading: false,
      isLoadingDelete: false,
      data: {
        id: null,
        name: '',
        date: null,
        isPaid: 0,
      },
    }
  },
  methods: {
    ...mapMutations({
      pushGlobalTimeoff: 'settings/pushGlobalTimeoff',
      updateGlobalTimeoff: 'settings/updateGlobalTimeoff',
      deleteGlobalTimeoff: 'settings/deleteGlobalTimeoff',
    }),
    async onDeleteClick() {
      this.isLoadingDelete = true
      try {
        let response = await ApiService.delete(
          `/api/space-roketin/global-time-off/${this.data.id}/delete`
        )

        this.deleteGlobalTimeoff(this.data.id)
        showToast(response.data.message, 'is-success', 'is-top')
      } catch (err) {
        showToast(err.response.data.message, 'is-danger', 'is-top')
      }
      this.isLoadingDelete = false
      this.$emit('close-modal')
    },
    async submit() {
      this.isLoading = true
      try {
        if (this.formMode === 'edit') {
          let response = await ApiService.put(
            `/api/space-roketin/global-time-off/${this.data.id}/update`,
            this.data
          )
          let updatedLeave = response.data
          showToast('Update Success', 'is-success', 'is-top')
          this.updateGlobalTimeoff(updatedLeave)
        } else {
          const initData = {
            id: this.data.id,
            isPaid: this.data.isPaid,
            name: this.data.name,
            date: moment(this.data.date).format(),
          }

          let response = await ApiService.post(
            '/api/space-roketin/global-time-off',
            initData
          )
          console.log('Response = ', response.data)
          let newLeave = response.data
          this.pushGlobalTimeoff(newLeave)
          showToast('New Event Added Successfully', 'is-success', 'is-top')
        }
      } catch (err) {
        showToast(err.response.data.message, 'is-danger', 'is-top')
      }
      this.isLoading = false
      this.$emit('close-modal')
    },
  },
  created() {
    // if (this.initialData) {
    //   this.initialData.date = new Date(this.initialData.date)
    //   this.data = { ...this.initialData }
    // }
  },
}
</script>
