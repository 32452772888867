<template>
  <r-page-modal
    :prompt-on-leave="false"
    v-if="isModalOpen"
    @hide="onCloseModalCallback"
    :is-modal="true"
    key="modalKey"
    class="modal-global-timeoff"
    remove-scrolling
  >
    <template #page-content>
      <header>
        <h3 class="modal-title has-text-weight-black">
          {{ titleModal }}
          <span class="office-pointer-click is-pulled-right">
            <b-icon
              icon="close"
              size="is-small"
              @click.native="onCloseModalCallback"
            />
          </span>
        </h3>
      </header>
      <slot name="content" />
    </template>
  </r-page-modal>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    onCloseModalCallback: Function,
    titleModal: String,
  },
  data() {
    return {
      isComponentModalActive: false,
    }
  },
}
</script>
